.bbcode__b {
    font-weight: 900;
}

.bbcode__i {
    font-style: italic;
    -webkit-font-feature-settings: "ital";
    font-feature-settings: "ital";
}

div.quote {
  border: solid $darker-text-color 1px;
  margin-left: 20px;
}

pre {
  border: solid $darker-text-color 1px;
}

@-webkit-keyframes pulse {
  0% {
    opacity: 1
  }

  to {
    opacity: .5
  }
}

@keyframes pulse {
  0% {
    opacity: 1
  }

  to {
    opacity: .5
  }
}

.bbcode__pulse {
  -webkit-animation: pulse 1s ease-in-out infinite;
  animation: pulse 1s ease-in-out infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate
}

.bbcode__spin {
    display: inline-block;
    -webkit-animation: fa-spin 4s infinite linear;
    animation: fa-spin 4s infinite linear;
}

.bbcode__flip-horizontal {
    display: inline-block;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
}

.bbcode__flip-vertical {
    display: inline-block;
    transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
}

.bbcode__color {
    color: attr(data-bbcodecolor color);
}

.bbcode__size {
    size: attr(data-bbcodesize size);
}