// Commonly used web colors
$black: #000000;            // Black
$white: #ffffff;            // White
$success-green: #79bd9a;    // Padua
$error-red: #df405a;        // Cerise
$warning-red: #ff5050;      // Sunset Orange
$gold-star: #30ddef;        // link color

// Values from the classic Mastodon UI
$classic-base-color: #170941;         // darker purple
$classic-primary-color: #58D9BA;      // highlight green
$classic-secondary-color: #30ddef;    // link color
$classic-highlight-color: #3a277d;    // lighter purple

// Variables for defaults in UI
$base-shadow-color: $black;
$base-overlay-background: $black;
$base-border-color: $white;
$simple-background-color: $white;
$valid-value-color: $success-green;
$error-value-color: $error-red;

// Tell UI to use selected colors
$ui-base-color: $classic-base-color;                  // Darkest
$ui-base-lighter-color: lighten($ui-base-color, 26%); // Lighter darkest
$ui-primary-color: $classic-primary-color;            // Lighter
$ui-secondary-color: $classic-secondary-color;        // Lightest
$ui-highlight-color: $classic-highlight-color;

// Variables for texts
$primary-text-color: $white;
$darker-text-color: $ui-primary-color;
$dark-text-color: $ui-base-lighter-color;
$secondary-text-color: $ui-secondary-color;
$highlight-text-color: $ui-highlight-color;
$action-button-color: $ui-base-lighter-color;
// For texts on inverted backgrounds
$inverted-text-color: $ui-base-color;
$lighter-text-color: $ui-base-lighter-color;
$light-text-color: $ui-primary-color;

// Language codes that uses CJK fonts
$cjk-langs: ja, ko, zh-CN, zh-HK, zh-TW;

// Variables for components
$media-modal-media-max-width: 100%;
// put margins on top and bottom of image to avoid the screen covered by image.
$media-modal-media-max-height: 80%;

$no-gap-breakpoint: 415px;

$font-sans-serif: 'mastodon-font-sans-serif';
$font-display: 'mastodon-font-display';
$font-monospace: 'mastodon-font-monospace';